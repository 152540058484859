import authAxios from '@/utils/authAxios';

export default {
  updateBond: (id, { bond }) => {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append('bond', bond);
      authAxios
        .post(
          process.env.VUE_APP_BACKEND_URL + `/bond/create-bond/${id}`,
          form,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteBond: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .delete(process.env.VUE_APP_BACKEND_URL + `/bond/delete-bond/${id}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateSatatusBond: (id, { accepted, rejected, comments }) => {
    return new Promise((resolve, reject) => {
      authAxios
        .post(process.env.VUE_APP_BACKEND_URL + `/bond/update-bond/${id}`, {
          accepted,
          rejected,
          comments,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getBondFile: id => {
    return new Promise((resolve, reject) => {
      authAxios
        .get(`${process.env.VUE_APP_BACKEND_URL}/bond/get-bond-file/${id}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
