<template>
  <div class="flex flex-col justify-start items-start min-height">
    <div
      v-if="reservation"
      class="border border-gray-200 sm:rounded-lg w-full overflow-hidden mb-8"
    >
      <table class="rounded-lg min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50 overflow-hidden">
          <tr>
            <th
              scope="col"
              class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
            >
              Código
            </th>
            <th
              scope="col"
              class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
            >
              Nombre Profesional
            </th>
            <th
              scope="col"
              class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
            >
              Fecha Reserva
            </th>
            <th
              scope="col"
              class="overflow-hidden rounded-lg px-6 py-3 text-left text-sm font-medium text-gray-500 capitalize tracking-wider"
            >
              Estado Reserva
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm w-full text-left font-medium text-gray-900">
                <div class="flex flex-col justify-center items-start">
                  <span class="inline-block uppercase"
                    ><span class="whitespace-normal break-word">{{
                      reservation.id
                    }}</span></span
                  >
                </div>
              </div>
              <!---->
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm w-full text-left font-medium text-gray-900">
                <div class="flex flex-col justify-center items-start">
                  <span class="inline-block capitalize !w-10">
                    <span class="whitespace-normal break-word capitalize"
                      >{{ reservation.block.user.names }}
                      {{ reservation.block.user.lastnames }}</span
                    > </span
                  ><span
                    class="inline-block w-auto text-green-900 bg-green-100 rounded-md px-2 mt-1 lowercase"
                  >
                    <span
                      v-if="reservation.block.user.doctor"
                      class="whitespace-normal break-word"
                      >{{ reservation.block.user.doctor.specialty }}</span
                    >
                    <span
                      v-if="reservation.block.user.superdoctor"
                      class="whitespace-normal break-word"
                      >{{ reservation.block.user.superdoctor.specialty }}</span
                    >
                    <span
                      v-if="reservation.block.user.psycholigst"
                      class="whitespace-normal break-word"
                      >{{ reservation.block.user.psycholigst.specialty }}</span
                    >
                  </span>
                </div>
              </div>
              <!---->
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm w-full text-left font-medium text-gray-900">
                <div class="flex flex-col justify-center items-start">
                  <span class="inline-block"
                    ><span class="whitespace-normal break-word">{{
                      formatDateNative(reservation.block.start_date)
                    }}</span></span
                  ><span class="inline-block"
                    ><span class="whitespace-normal break-word">{{
                      formatDateNativeHour(reservation.block.start_date)
                    }}</span></span
                  >
                </div>
              </div>
              <!---->
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="text-sm w-full text-left font-medium text-gray-900">
                <div class="flex flex-col justify-center items-start">
                  <span class="inline-block"
                    ><span class="whitespace-normal break-word">{{
                      !reservation.bond
                        ? 'Pendiente pago'
                        : reservation.bond.accepted
                        ? 'Confirmada'
                        : reservation.bond.rejected
                        ? 'Rechazada'
                        : 'Pendiente Aprobación'
                    }}</span></span
                  >
                </div>
              </div>
              <!---->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <span class="text-xl text-gray-900 font-semibold mb-4">Cargar Bono</span>
    <div class="w-full pb-4 grid grid-cols-3">
      <div class="col-span-1 flex flex-row items-center">
        <button
          type="button"
          @click.prevent="selectFile()"
          class="w-full mr-2 justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none sm:text-sm"
        >
          <span v-if="fileBond != ''">Seleccionar otro archivo</span>
          <span v-else>Seleccionar archivo</span>
        </button>
      </div>
      <div class="col-span-2 input-group flex flex-row items-center">
        <div
          class="border-2 border-dotted border-gray-400 rounded-md w-full py-2 text-gray-800"
        >
          <input
            id="avatarInput"
            type="file"
            name="avatar"
            class="hidden"
            ref="BondFile"
            accept="application/pdf"
            @change="uploadFile"
          />
          <label v-if="fileBond != ''" class="" for="avatarInput">{{
            fileBond.name
          }}</label>
          <label v-else class="text-gray-800" for="avatarInput"
            >ó arrastrar aquí</label
          >
        </div>
      </div>
    </div>
    <div class="w-full flex justify-end mb-4">
      <button
        type="button"
        v-if="fileBondPreview"
        @click="sendBond"
        :disabled="bondSent"
        :class="
          bondSent
            ? 'bg-gray-600 opacity-75 cursor-not-allowed disabled mb-2 my-4 mr-2 text-gray-50 font-bold px-3 py-2 rounded-md text-sm font-medium'
            : 'bg-green-600 mb-2 my-4 mr-2 hover:bg-green-700 text-gray-50 font-bold px-3 py-2 rounded-md text-sm font-medium'
        "
      >
        <span v-if="bondSent">
          Bono enviado
        </span>
        <span v-else>
          Enviar el bono
        </span>
      </button>
      <button
        type="button"
        v-if="fileBondPreview"
        @click="deleteBond"
        class="bg-gray-500 mb-2 my-4 hover:bg-gray-600 text-gray-50 font-bold px-3 py-2 rounded-md text-sm font-medium"
      >
        Borrar archivo
      </button>
    </div>
    <span class="text-xl text-gray-900 font-semibold mt-4 mb-4"
      >Bono de la reserva</span
    >
    <div class="grid grid-cols-1 w-full gap-2">
      <div
        class="col px-4 py-2 rounded-md border-dashed border-2 border-gray-200 flex flex-col"
      >
        <span class="text-lg text-gray-700 font-semibold mb-4 w-full text-left"
          >Archivo</span
        >
        <div
          :class="
            reservation && reservation.bond && reservation.bond.pdf
              ? 'pt-2 pb-4 px-5 '
              : 'pb-10 pt-3 px-5 '
          "
          class="flex flex-col justify-center items-center"
        >
          <svg
            v-if="!(reservation && reservation.bond && reservation.bond.pdf)"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8 text-gray-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>

          <span
            v-if="reservation && reservation.bond && reservation.bond.pdf"
            class="text-sm text-gray-400 font-semibold"
            >{{ reservation.bond.name }}</span
          >
          <span v-else class="text-sm text-gray-400 font-semibold"
            >Sin Archivo</span
          >
        </div>
        <pdf
          class="w-full"
          v-if="reservation && reservation.bond && reservation.bond.pdf"
          :src="reservation.bond.pdf"
          :page="1"
        />
        <div class="w-full flex justify-end">
          <button
            type="button"
            v-if="reservation && reservation.bond && reservation.bond.pdf"
            @click="deleteBondDatabase"
            class="bg-red-200 mb-2 my-4 hover:bg-red-300 text-red-900 font-bold px-3 py-2 rounded-md text-sm font-medium"
          >
            Borrar archivo
          </button>
        </div>
      </div>
    </div>
    <span class="text-xl text-gray-900 font-semibold mt-8 mb-4"
      >Estado del Bono</span
    >
    <div
      class="w-full px-4 py-2 rounded-md border-dashed border-2 border-gray-200 flex"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-12 h-12 text-gray-500"
      >
        <path
          v-if="!reservation || !reservation.bond"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
        />
        <path
          v-if="reservation && reservation.bond && reservation.bond.accepted"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
        <path
          v-if="reservation && reservation.bond && reservation.bond.rejected"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
        <path
          v-if="
            reservation &&
              reservation.bond &&
              !reservation.bond.accepted &&
              !reservation.bond.rejected
          "
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>

      <div class="ml-2 flex flex-col justify-start items-start">
        <span
          v-if="!reservation || !reservation.bond"
          class="text-md font-semibold text-gray-600"
          >Bono no envíado</span
        >
        <span
          v-if="reservation && reservation.bond && reservation.bond.accepted"
          class="text-md font-semibold text-gray-600"
          >Bono Confirmado</span
        >
        <span
          v-if="reservation && reservation.bond && reservation.bond.rejected"
          class="text-md font-semibold text-gray-600"
          >Bono Rechazado</span
        >
        <span
          v-if="
            reservation &&
              reservation.bond &&
              !reservation.bond.accepted &&
              !reservation.bond.rejected
          "
          class="text-md font-semibold text-gray-600"
          >Bono pendiente por revisar</span
        >
        <span
          v-if="reservation && reservation.bond && reservation.bond.comments"
          class="text-md font-medium text-gray-600"
          >{{ reservation.bond.comments }}</span
        >
        <span v-else class="text-md font-medium text-gray-400"
          >Sin comentarios ...</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pdf from 'vue-pdf';
import ReservationService from '@/services/reservationService';
import BondService from '@/services/bondService';
import moment from 'moment';

export default {
  name: 'Bond',
  components: {
    pdf,
  },
  data() {
    return {
      fileBond: '',
      fileBondPreview: '',
      bondSent: false,
      reservation: null,
      backend: process.env.VUE_APP_BACKEND_URL.replace('/api', ''),
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    formatDateNative(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('L');
    },
    formatDateNativeHour(date) {
      moment.locale('es');
      let date_temp = moment(date, 'YYYY-MM-DD HH:mm');
      date_temp.locale(false);
      return date_temp.format('HH:mm A');
    },
    updateData() {
      ReservationService.getReservation(this.$route.params.reservation_id)
        .then(response => {
          if (response.data.reservation && response.data.reservation.bond) {
            BondService.getBondFile(this.$route.params.reservation_id)
              .then(response_bond => {
                let url = Buffer.from(response_bond.data).toString('base64');
                let reservation_temp = response.data.reservation;
                reservation_temp.bond.pdf = `data:application/pdf;base64,${url}`;
                this.reservation = reservation_temp;
              })
              .catch(() => {
                this.reservation = response.data.reservation;
              });
          } else {
            this.reservation = response.data.reservation;
          }
        })
        .catch(() => {
          this.$router.replace('/home');
        });
    },
    selectFile() {
      if (this.$refs.BondFile) {
        this.$refs.BondFile.click();
      }
    },
    uploadFile() {
      if (this.$refs.BondFile.files[0].size >= 2000000) {
        Notification.fire({
          type: 'error',
          title: 'Imagen demasiado Grande!',
        });
      } else {
        this.fileBond = this.$refs.BondFile.files[0];
        var reader = new FileReader();
        reader.addEventListener(
          'load',
          function() {
            this.fileBondPreview = reader.result;
          }.bind(this),
          false
        );
        if (this.fileBond) {
          if (/\.(pdf)$/i.test(this.fileBond.name)) {
            reader.readAsDataURL(this.fileBond);
          }
        }
      }
    },
    sendBond() {
      if (!this.bondSent) {
        this.$AreYouSure.fire().then(result => {
          if (result.isConfirmed) {
            BondService.updateBond(this.reservation.id, {
              bond: this.fileBond,
            })
              .then(() => {
                this.bondSent = true;
                this.fileBond = '';
                setTimeout(() => {
                  this.bondSent = false;
                }, 10000);
                this.$Success.fire({
                  title: 'Bono enviado',
                });
                this.updateData();
              })
              .catch(() => {
                this.$Error.fire({
                  title: 'Error al enviar el bono',
                });
              });
          }
        });
      }
    },
    deleteBond() {
      this.$AreYouSure.fire().then(result => {
        if (result.isConfirmed) {
          this.$refs.BondFile.value = null;
          this.fileBond = '';
          this.fileBondPreview = '';
        }
      });
    },
    deleteBondDatabase() {
      this.$AreYouSure.fire().then(result => {
        if (result.isConfirmed) {
          BondService.deleteBond(this.reservation.id)
            .then(() => {
              this.$Success.fire({
                title: 'Bono eliminado',
              });
              this.updateData();
            })
            .catch(() => {
              this.$Error.fire({
                title: 'Error al eliminar el bono',
              });
            });
        }
      });
    },
  },
  watch: {
    '$route.params.reservation_id'() {
      this.updateData();
    },
  },
  computed: {
    ...mapGetters({
      user: ['authentication/user'],
    }),
  },
};
</script>

<style scoped>
.min-height {
  min-height: 80vh;
}
</style>
